<template>
    <div class="contenedor">
        <div class="central_">

            <Bienvenidos />

            <QuienesSomos id="quienes" />

            <!-- <ComoLoHacemos id="comolohacemos" /> -->

            <Especialidades id="especialidades" />

            <!-- <Gente /> -->

            <Contactenos id="contactanos" />

            <Pie />

        </div>
    </div>
</template>

<script>

const $ = require('jquery')
window.$ = $


import Bienvenidos from './components/Secciones/Bievenidos.vue'
import QuienesSomos from './components/Secciones/Quienes_somos.vue'
import ComoLoHacemos from './components/Secciones/Como_lo_hacemos.vue'
import Especialidades from './components/Secciones/Especialidades.vue'
import Gente from './components/Secciones/Gente.vue'
import Contactenos from './components/Secciones/Contactenos.vue'
import Pie from './components/Secciones/Pie.vue'

export default {
    name: 'Estec',
    // metaInfo:{
    //     title: 'Bolsas Asmi',
    //     titleTemplate: '%s | Fábrica de Bolsas'
    // },
    components:{
        Bienvenidos,
        QuienesSomos,
        ComoLoHacemos,
        Especialidades,
        Gente,
        Contactenos,
        Pie
    },
    data() {
        return {
            
        }
    },
    mounted() {
        $(function() {
            $(".ancla").click(function(evento){
            evento.preventDefault();
            var codigo = "#" + $(this).data("ancla");
            $("html,body").animate({scrollTop: $(codigo).offset().top}, 1000);
            });
        });
    },
}
</script>

<style>

    @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

    html{
        background-color: #edf0f5;
    }

    a{
        text-decoration: none !important;
        color: white;
    }

    .contenedor{
        width: 100%;
        height: auto;
        /* padding-left: 100px;
        padding-right: 100px; */
    }
        .central_{
            width: 100%;
            height: 100%;
        }

            .seccion{
                width: 100%;
                height: 700px;
                position: relative;
            }

        .espacio{
            width: 80px;
            height: auto;
            border: 1px solid orangered;
        }
    

        .gs{
            width: 90%;
            height: 100%;
            display: flex;
        }

        .imagenes_descripcion_especialidades{
            width: 50%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            
        }
            .tarjeta{
                border-radius: 8px;
                overflow: hidden;
                margin-top: 11px;
                width: 420px;
            }

            .tarjeta_imagen{
                width: 100%;
                height: 240px;
                overflow: hidden;
                object-fit: cover;
            }

            .tarjeta_texto{
                display: flex;
                background-color: white;
            }
                .tarjeta_numero{
                    width: 54px;
                    height: 90px;
                    font-size: 45px;
                    font-weight: bold;
                    color: #1bc6c0;
                    margin-left: 20px;
                    display: flex;
                    align-items: center;
                }

                .inner_tarjeta_texto{
                    width: calc(100% - 54px);
                    height: 90px;
                    padding-left: 10px;
                    font-size: 28px;
                    line-height: 29px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }

        .texto_descripcion_especialidades{
            width: 50%;
            height: 100%;
            padding: 20px;
            box-sizing: border-box;
        }

            .titulo_especialidad{
                width: 100%;
                height: auto;
                font-size: 25px;
                color: #1bc6c0;
                font-weight: bold;
            }

            .lista_datos_especialida{
                font-size: 16px;
            }
                .lista_datos_especialida ul{
                    color: #7a7a7a;
                }

    /* MEDIA QUERYS */

    @media only screen and (min-width : 320px) and (max-width: 768px){
         .contenedor{
             padding: 0;
         }

         .seccion{
             padding-left: 10px;
             padding-right: 10px;
             box-sizing: border-box;
         }

            /* .seccion_sub_1{
                display: block;
            }

                .bienvenidos{
                    width: 100%;
                    padding-right: 0px;
                }

                .carrusel{
                    width: 100%;
                    display: none;
                } */


        .gs{
            display: block;
            width: 100%;
        }

            .imagenes_descripcion_especialidades{
                width: 100%;
            }

            .texto_descripcion_especialidades{
                width: 100%;
            }
                .titulo_especialidad{
                    font-size: 23px;
                }

            .tarjeta{
                width: initial;
            }

    }
</style>