<template>
    <div class="contenedor_gente">
        <img src="@/assets/people.png" class="imagen_gente">
    </div>
</template>

<script>
export default {
    name: 'Gente'
}
</script>

<style>
    .contenedor_gente{
        width: 100%;
        height: 250px;
        overflow: hidden;
        position: relative;
    }

        .imagen_gente{
            width: 100%;
            height: 100%;
            /* position: absolute;
            top: -167px;
            left: 0; */
            /* height: 100%; */
        }

     @media only screen and (min-width : 320px) and (max-width: 768px){
         .contenedor_gente{
            overflow: hidden;
         }

            .imagen_gente{
                /* object-fit: cover; */
                overflow: hidden;
                width: auto;
            }
     }


     @media only screen and (min-width : 780px) and (max-width: 1280px){
         .contenedor_gente{
            overflow: hidden;
         }

            .imagen_gente{
                /* object-fit: cover; */
                overflow: hidden;
                width: auto;
            }
     }
</style>