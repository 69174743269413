<template>
    <div class="contenedor_total">
        <div class="boton_cerrar" @click="cerrar">
            X
        </div>


        <div class="cuadro_foto">

            <img :src="require(`@/assets/fotos_fabrica/${id_foto}.jpeg`)" style="width: 100%;" alt="">

        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFotos',
    props:['id_foto'],
    data() {
        return {
            
        }
    },
    methods: {
        cerrar(){
            this.$emit('salir')
        }
    },
    mounted() {
        console.log(this.id_foto)
    },
}
</script>

<style>
    .contenedor_total{
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, .9);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

        .cuadro_foto{
            width: 700px;
            height: auto;
            /* background-color: white; */
        }

        .boton_cerrar{
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: white;
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            transition: .4s ease;

        }

            .boton_cerrar:hover{
                background-color: rgba(0, 0, 0, .7);
                color: white;
            }
</style>