<template>
    <div class="seccion mod_seccion_bievenidos">
        <div class="menu" >
            <div class="seccion_menu">
                <a href="#" data-ancla="quienes" class="ancla">Quiénes Somos</a>
            </div>
            <!-- <div class="seccion_menu">
                <a href="#" data-ancla="comolohacemos" class="ancla">Cómo lo Hacemos</a>
            </div> -->
            <div class="seccion_menu"> 
                <a href="#" data-ancla="especialidades" class="ancla">Clientes</a>
            </div>
            <div class="seccion_menu">
                <a href="#" data-ancla="contactanos" class="ancla">Contáctanos</a>
            </div>
            <!-- <div class="boton_registrate" @click="abrirModal">
                ¡Regístrate!
            </div> -->

           

            <ul id="slide-out" class="sidenav">
                
                <li><a href="#!">Menú</a></li>
                <li><div class="divider"></div></li>
                <li><a class="waves-effect ancla" href="#" data-ancla="quienes">Quiénes Somos</a></li>
                <!-- <li><a class="waves-effect ancla" href="#" data-ancla="comolohacemos">Cómo lo Hacemos</a></li> -->
                <li><a class="waves-effect ancla" href="#" data-ancla="especialidades" >Clientes</a></li>
                <li><a class="waves-effect ancla" href="#" data-ancla="contactanos" >Contáctanos</a></li>
                <li><a class="waves-effect ancla" href="#" @click="abrirModal" >¡Registrate!</a></li>
            </ul>
            <a href="#" data-target="slide-out" style="display:flex; justify-content: center; align-items: center;" class="sidenav-trigger"><i class="material-icons iconox" style="font-size: 35px;">menu</i></a>
        </div>
        <div class="seccion_sub_1">
            <div class="bienvenidos">

                <div class="texto_bienvenidos_curvo">
                    <div id='warped' style="color: white;">
                        
                    </div>
                </div>

                <div class="logo_estec">
                    <img src="@/assets/logo_asmi.png" style="width: 100%;" alt="">
                    
                </div>

                <div class="texto_bievenida_1">
                    <i>Su Marca en Movimiento</i>
                </div>


                <!-- <div class="texto_bievenida_2">
                    PREPARACIÓN TÉCNICA ESCOLAR
                </div>

                <div class="texto_bievenida_3">
                    con Expertos Comprobados a
                </div>

                <div class="texto_bievenida_3">
                    Nivel Internacional.
                </div>
                
                <div class="btn_acerca_de">
                    
                    <a href="#" data-ancla="quienes" style="color: #01cccc" class="ancla">Más Acerca de ETES</a>
                </div> -->

            </div>



            <div class="carrusel" >
                <div class="carousel" >
                    <div class="carousel-item">
                        <img src="@/assets/carousel/5r.png" alt="">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/carousel/6r.png" alt="">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/carousel/3r.png" alt="">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/carousel/4r.png" alt="">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/carousel/1r.png" alt="">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/carousel/2r.png" alt="">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/carousel/3r.png" alt="">
                    </div>
                    
                </div>
            </div>


 
        </div>

        <!-- Gradient naranja -->
        
        <div class="gradient_orange">
            <img src="@/assets/Gradient_Header.png" class="gradient_img" style="width: 100%;" alt="">
        </div>

        <!-- Modal para registro -->

        <div v-if="modal" class="modal_registro_capa_1">
            <div class="modal_registro_capa_2">
                
                    <div class="row">
                        <div class="col s12">
                            <h3>
                                Registro
                            </h3>
                        </div>
                        <div class="input-field col s12 m6">
                            <input type="text" v-model="nombres" class="validate">
                            <label for="icon_prefix">Nombres</label>
                        </div>
                        <div class="input-field col s12 m6">
                            <input type="text" v-model="apellidos" class="validate">
                            <label for="icon_prefix">Apellidos</label>
                        </div>
                        <div class="input-field col s12">
                            <input type="email" v-model="correo" class="validate">
                            <label for="icon_prefix">E-mail</label>
                        </div>
                        <div class="input-field col s12">
                            <input type="text" v-model="ciudad" class="validate">
                            <label for="icon_prefix">Ciudad</label>
                        </div>
                        <div class="input-field col s12">
                            <input type="text" v-model="mensaje" class="validate">
                            <label for="icon_prefix">Mensaje</label>
                        </div>
                        <div class="input-field col s12" style="display:flex; flex-direction: row-reverse;">
                            <a class="waves-effect waves-light btn" @click="enviarmensaje">Enviar</a>
                        </div>
                    </div>
                <div class="cerrarMod" @click="cerrarModal">
                    <i class="fas fa-times-circle"></i>
                </div>
               
            </div>
        </div>

    </div>
</template>

<script>

import M from '@/assets/functions/Materialize'
import axios from 'axios'

export default {
    name: 'Bienvenidos',
    data() {
        return {
            modal: false,
            nombres: '',
            apellidos: '',
            correo: '',
            ciudad: '',
            mensaje: ''
        }
    },
    methods: {
        inicializador(){
            const elementosCarousel = document.querySelectorAll('.carousel')
            M.Carousel.init(elementosCarousel, {
                duration: 150,
                dist: -80, //-80
                shift: 5,
                padding: 5,
                numVisible: 3,
                indicators: true,
                noWrap: false,
                autoScroll: 3000,
            })

            let indicatorItems = document.querySelectorAll('.carousel .indicator-item'),
                slideTime = 3000,
                activeClass = "active"

            setInterval(() => {
                indicatorItems.forEach(el =>{
                    if(el.classList.contains(activeClass)){
                        let sib = el.nextElementSibling
                        if(sib == null){
                            indicatorItems[0].click()
                        }else{
                            sib.click()
                        }
                    }
                })
            }, slideTime);

            var elems = document.querySelectorAll('.sidenav');
            var instances = M.Sidenav.init(elems, {
                edge: 'left'
            });

           

        },
        closeMenu(instance){
            const elementosCarousel = document.querySelectorAll('.carousel')
            var instance = M.Carousel.getInstance(elementosCarousel);
            instance.next()
        },
        carousel_autoPlay(){
            
        },
        abrirModal(){
            this.modal = true
        },
        cerrarModal(){
            this.modal = false
        },
        async enviarmensaje(){
            if (this.nombres == '' || this.apellidos == '' || this.correo == '' || this.ciudad == '' || this.mensaje == '') {
                alert('Todos los campos deben estar llenos')
            }else{
                await axios.get(`https://www.escuelatecnicape.com/x.php?nombres=${this.nombres}&apellidos=${this.apellidos}&correo=${this.correo}&ciudad=${this.ciudad}&mensaje=${this.mensaje}`)

                alert('Muchas gracias! estarémos contactandote lo más pronto posible.')
                this.nombres = ''
                this.apellidos = ''
                this.correo = ''
                this.ciudad = ''
                this.mensaje = ''
            }
        },
        
    },
    mounted() {
        this.inicializador()
        //this.carousel_autoPlay()
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
    

    .gradient_orange{
        width: 790px;
        height: 700px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        
    }

        .modal_registro_capa_1{
            width: 100%;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 200px;
            box-sizing: border-box;
        }

            .modal_registro_capa_2{
                width: 500px;
                height: auto;
                background-color: white;
                border-radius: 4px;
                padding: 20px;
                box-sizing: border-box;
                position: relative;
            }

            .cerrarMod{
                position: absolute;
                top: 15px;
                right: 15px;
                font-size: 25px;
                cursor: pointer;
            }
    .menu{
        width: 100%;
        height: 60px;
        display: flex;
        padding-left: 100px;
        padding-right: 100px;
        box-sizing: border-box;
        z-index: 1200;
    }

        .sidenav{
            display: none;
        }

        .sidenav-trigger{
            display: none;
        }

        .material-icons{
            display: none;
        }



    .seccion_sub_1{
        width: 100%;
        height: calc(100% - 60px);
        display: flex;
    }

        .bienvenidos{
            width: 40%;
            height: 100%;
            padding-left: 100px;
        }

            .texto_bienvenidos_curvo{
                display: flex;
                justify-content: center;
                width: 100%;
                height: 110px;
                position: relative;
            }


            .logo_estec{
                width: 300px;
                height: auto;
            }

            .texto_bievenida_1{
                /* font-family: 'Roboto', sans-serif; */
                /* font-family: 'Lalezar', cursive; */
                /* font-family: 'Open Sans', sans-serif; */
                /* font-family: 'Quicksand', sans-serif; */
                font-family: 'Nunito', sans-serif;
                font-size: 40px;
                width: 100%;
                height: auto;
                color: white;
                margin-top: 30px;
                padding-left: 8px;
            }

            .texto_bievenida_2{
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                width: 350px;
                height: auto;
                background-color: #1bc6c0;
                border: 2px solid white;
                color: white;
                padding-left: 8px;
            }

            .texto_bievenida_3{
                font-family: 'Roboto', sans-serif;
                font-size: 20px;
                width: 100%;
                height: auto;
                color: white;
                padding-left: 8px;
            }

            .btn_acerca_de{
                font-family: 'Roboto', sans-serif;
                width: 200px;
                height: auto;
                background-color: white;
                color: #01cccc;
                font-size: 18px;
                padding: 10px;
                border-radius: 10px;
                margin-top: 70px;
            }


               


        .carrusel{
            width: 50%;
            height: 100%;
            /* padding-right: 100px; */
        }

            .carousel{
                width: 100%;
                height: 650px;
                min-width: 444px;
            }

                .carousel .carousel-item{
                    width: 300px;
                    height: 444px;
                }

                .carousel .carousel-item img{
                    /* width: 100%;
                    height: 100%; */
                    object-fit: cover;
                    border-radius: 25px;

                    position: relative;
                    left: 0;
                    top: 0;
                }




    .seccion_menu{
        width: auto;
        height: 100%;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
    }

    .boton_registrate{
        
        width: 150px;
        height: 50px;
        border: 2px solid #fe530b;
        color: #fe530b;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        font-weight: bold;
        /* margin-left: 250px; */
        position: absolute;
        right: 150px;
        top: 10px;
        cursor: pointer;
    }


/* MEDIA QUERYS */

    @media only screen and (min-width : 320px) and (max-width: 768px){
        .gradient_orange{
            display: none;
        }

        #warped{
            width: 300px;
            height: 110px;
            position: relative;
            font-size: 14px;
        }

        .mod_seccion_bievenidos{
            background-color: #fea601;
            padding-left: 35px !important;
            padding-right: 35px !important;
            box-sizing: border-box;
        }

        .modal_registro_capa_1{
            padding: 0;
        }

        .menu{
            /* display: none; */
            padding-left: 0px;
            padding-right: 0px;
            display: flex;
            flex-direction: row-reverse;
        }
            .sidenav{
                display: block;
            }

            .sidenav-trigger{
                display: block;
            }

            .material-icons{
                display: block;
            }

            .seccion_menu{
                display: none;
            }

            .boton_registrate{
                display: none;
            }

        .bienvenidos{
            padding-left: 0px;
        }
            .texto_bienvenidos_curvo{
                 /* VERIFICAR EN OTROS TAMAÑOS DE PANTALLA O VER SI SE PUEDE VOVLER EL CENTRADO AUTOMATICO */
            }

            .logo_estec{
                width: 100%;
            }

        .seccion_sub_1{
            display: block;
        }

            .bienvenidos{
                width: 100%;
                padding-right: 0px;
            }

            .carrusel{
                width: 100%;
                display: none;
            }


        .texto_bievenida_2{
            width: 295px;
            font-size: 17px;
        }
    } 



/* CSS DE LA PALABRA "¡BIENVENIDOS!" CURVO*/

#warped {
    /* position: relative; 
    display: block; 
    width:auto; 
    height:auto; */
}

        #warped>span[class^=w]:nth-of-type(n+0){display:block; position:absolute;
        -moz-transform-origin:50% 100%; -webkit-transform-origin:50% 100%; -o-transform-origin:50%
        100%; -ms-transform-origin:50% 100%; transform-origin:50% 100%; }

        #warped span{font-family:'Didact Gothic';font-size:25px;font-weight:bold;font-style:normal;
        line-height:0.65; white-space:pre; overflow:visible; padding:0px;}

        #warped .w0 {-moz-transform: rotate(-0.63rad);-webkit-transform: rotate(-0.63rad);-o-transform:
        rotate(-0.63rad);-ms-transform: rotate(-0.63rad); transform: rotate(-0.63rad);
        width: 8px; height: 16px; left: 7.58px; top: 63.12px;}

        #warped .w1 {-moz-transform: rotate(-0.53rad);-webkit-transform: rotate(-0.53rad);-o-transform:
        rotate(-0.53rad);-ms-transform: rotate(-0.53rad); transform: rotate(-0.53rad);
        width: 17px; height: 16px; left: 21.97px; top: 50.92px;}

        #warped .w2 {-moz-transform: rotate(-0.44rad);-webkit-transform: rotate(-0.44rad);-o-transform:
        rotate(-0.44rad);-ms-transform: rotate(-0.44rad); transform: rotate(-0.44rad);
        width: 8px; height: 16px; left: 46.44px; top: 40.56px;}

        #warped .w3 {-moz-transform: rotate(-0.34rad);-webkit-transform: rotate(-0.34rad);-o-transform:
        rotate(-0.34rad);-ms-transform: rotate(-0.34rad); transform: rotate(-0.34rad);
        width: 15px; height: 16px; left: 62.86px; top: 32.48px;}

        #warped .w4 {-moz-transform: rotate(-0.23rad);-webkit-transform: rotate(-0.23rad);-o-transform:
        rotate(-0.23rad);-ms-transform: rotate(-0.23rad); transform: rotate(-0.23rad);
        width: 18px; height: 16px; left: 86.78px; top: 25.07px;}

        #warped .w5 {-moz-transform: rotate(-0.11rad);-webkit-transform: rotate(-0.11rad);-o-transform:
        rotate(-0.11rad);-ms-transform: rotate(-0.11rad); transform: rotate(-0.11rad);
        width: 18px; height: 16px; left: 114.37px; top: 20.39px;}

        #warped .w6 {-moz-transform: rotate(0rad);-webkit-transform: rotate(0rad);-o-transform:
        rotate(0rad);-ms-transform: rotate(0rad); transform: rotate(0rad); width:
        15px; height: 16px; left: 142.32px; top: 19px;}

        #warped .w7 {-moz-transform: rotate(0.11rad);-webkit-transform: rotate(0.11rad);-o-transform:
        rotate(0.11rad);-ms-transform: rotate(0.11rad); transform: rotate(0.11rad);
        width: 18px; height: 16px; left: 167.26px; top: 20.58px;}

        #warped .w8 {-moz-transform: rotate(0.21rad);-webkit-transform: rotate(0.21rad);-o-transform:
        rotate(0.21rad);-ms-transform: rotate(0.21rad); transform: rotate(0.21rad);
        width: 8px; height: 16px; left: 194.94px; top: 24.34px;}

        #warped .w9 {-moz-transform: rotate(0.31rad);-webkit-transform: rotate(0.31rad);-o-transform:
        rotate(0.31rad);-ms-transform: rotate(0.31rad); transform: rotate(0.31rad);
        width: 18px; height: 16px; left: 212.15px; top: 30.3px;}

        #warped .w10 {-moz-transform: rotate(0.42rad);-webkit-transform: rotate(0.42rad);-o-transform:
        rotate(0.42rad);-ms-transform: rotate(0.42rad); transform: rotate(0.42rad);
        width: 18px; height: 16px; left: 238.24px; top: 40.42px;}

        #warped .w11 {-moz-transform: rotate(0.53rad);-webkit-transform: rotate(0.53rad);-o-transform:
        rotate(0.53rad);-ms-transform: rotate(0.53rad); transform: rotate(0.53rad);
        width: 14px; height: 16px; left: 263.23px; top: 52.52px;}

        #warped .w12 {-moz-transform: rotate(0.62rad);-webkit-transform: rotate(0.62rad);-o-transform:
        rotate(0.62rad);-ms-transform: rotate(0.62rad); transform: rotate(0.62rad);
        width: 8px; height: 16px; left: 283.75px; top: 64.09px;}

        #warped .w13 {-moz-transform: rotate(-5.89rad);-webkit-transform: rotate(-5.89rad);-o-transform:
        rotate(-5.89rad);-ms-transform: rotate(-5.89rad); transform: rotate(-5.89rad);
        width: 13px; height: 24px; left: 206.77px; top: 55.22px;}

        #warped .w14 {-moz-transform: rotate(-5.72rad);-webkit-transform: rotate(-5.72rad);-o-transform:
        rotate(-5.72rad);-ms-transform: rotate(-5.72rad); transform: rotate(-5.72rad);
        width: 20px; height: 24px; left: 221.95px; top: 65.01px;}

        #warped .w15 {-moz-transform: rotate(-5.55rad);-webkit-transform: rotate(-5.55rad);-o-transform:
        rotate(-5.55rad);-ms-transform: rotate(-5.55rad); transform: rotate(-5.55rad);
        width: 9px; height: 24px; left: 243.34px; top: 77.19px;}

        #warped .w16 {-moz-transform: rotate(-5.37rad);-webkit-transform: rotate(-5.37rad);-o-transform:
        rotate(-5.37rad);-ms-transform: rotate(-5.37rad); transform: rotate(-5.37rad);
        width: 28px; height: 24px; left: 249.56px; top: 94.39px;}

        #warped .w17 {-moz-transform: rotate(-5.16rad);-webkit-transform: rotate(-5.16rad);-o-transform:
        rotate(-5.16rad);-ms-transform: rotate(-5.16rad); transform: rotate(-5.16rad);
        width: 18px; height: 24px; left: 269.17px; top: 118.66px;}

        #warped .w18 {-moz-transform: rotate(-5rad);-webkit-transform: rotate(-5rad);-o-transform:
        rotate(-5rad);-ms-transform: rotate(-5rad); transform: rotate(-5rad); width:
        21px; height: 24px; left: 276.11px; top: 141.04px;}

        #warped .w19 {-moz-transform: rotate(-4.88rad);-webkit-transform: rotate(-4.88rad);-o-transform:
        rotate(-4.88rad);-ms-transform: rotate(-4.88rad); transform: rotate(-4.88rad);
        width: 12px; height: 24px; left: 285.36px; top: 162.16px;}

        #warped .w20 {-moz-transform: rotate(-4.77rad);-webkit-transform: rotate(-4.77rad);-o-transform:
        rotate(-4.77rad);-ms-transform: rotate(-4.77rad); transform: rotate(-4.77rad);
        width: 20px; height: 24px; left: 283.63px; top: 183.09px;}

        #warped .w21 {-moz-transform: rotate(-4.77rad);-webkit-transform: rotate(-4.77rad);-o-transform:
        rotate(-4.77rad);-ms-transform: rotate(-4.77rad); transform: rotate(-4.77rad);
        width: 16px; height: 24px; left: 286.36px; top: 208.9px;}

        #warped .w22 {-moz-transform: rotate(-4.92rad);-webkit-transform: rotate(-4.92rad);-o-transform:
        rotate(-4.92rad);-ms-transform: rotate(-4.92rad); transform: rotate(-4.92rad);
        width: 33px; height: 24px; left: 281.67px; top: 238.13px;}

        #warped .w23 {-moz-transform: rotate(-5.08rad);-webkit-transform: rotate(-5.08rad);-o-transform:
        rotate(-5.08rad);-ms-transform: rotate(-5.08rad); transform: rotate(-5.08rad);
        width: 10px; height: 24px; left: 300.41px; top: 262.96px;}

        #warped .w24 {-moz-transform: rotate(-5.18rad);-webkit-transform: rotate(-5.18rad);-o-transform:
        rotate(-5.18rad);-ms-transform: rotate(-5.18rad); transform: rotate(-5.18rad);
        width: 9px; height: 24px; left: 306.92px; top: 276.64px;}

        #warped .w25 {-moz-transform: rotate(-5.34rad);-webkit-transform: rotate(-5.34rad);-o-transform:
        rotate(-5.34rad);-ms-transform: rotate(-5.34rad); transform: rotate(-5.34rad);
        width: 24px; height: 24px; left: 310.39px; top: 294.84px;}

        #warped .w26 {-moz-transform: rotate(-5.52rad);-webkit-transform: rotate(-5.52rad);-o-transform:
        rotate(-5.52rad);-ms-transform: rotate(-5.52rad); transform: rotate(-5.52rad);
        width: 11px; height: 24px; left: 331.67px; top: 312.01px;}

        #warped .w27 {-moz-transform: rotate(-5.64rad);-webkit-transform: rotate(-5.64rad);-o-transform:
        rotate(-5.64rad);-ms-transform: rotate(-5.64rad); transform: rotate(-5.64rad);
        width: 11px; height: 24px; left: 343.36px; top: 322px;}

        #warped .w28 {-moz-transform: rotate(-5.8rad);-webkit-transform: rotate(-5.8rad);-o-transform:
        rotate(-5.8rad);-ms-transform: rotate(-5.8rad); transform: rotate(-5.8rad);
        width: 17px; height: 24px; left: 356.37px; top: 332.13px;}

        #warped .w29 {-moz-transform: rotate(-6rad);-webkit-transform: rotate(-6rad);-o-transform:
        rotate(-6rad);-ms-transform: rotate(-6rad); transform: rotate(-6rad); width:
        20px; height: 24px; left: 377.08px; top: 341.14px;}

        #warped .w30 {-moz-transform: rotate(-6.16rad);-webkit-transform: rotate(-6.16rad);-o-transform:
        rotate(-6.16rad);-ms-transform: rotate(-6.16rad); transform: rotate(-6.16rad);
        width: 9px; height: 24px; left: 401.29px; top: 345.04px;}

        #warped .w31 {-moz-transform: rotate(-6.31rad);-webkit-transform: rotate(-6.31rad);-o-transform:
        rotate(-6.31rad);-ms-transform: rotate(-6.31rad); transform: rotate(-6.31rad);
        width: 16px; height: 24px; left: 415.28px; top: 345.97px;}

        #warped .w32 {-moz-transform: rotate(-6.57rad);-webkit-transform: rotate(-6.57rad);-o-transform:
        rotate(-6.57rad);-ms-transform: rotate(-6.57rad); transform: rotate(-6.57rad);
        width: 37px; height: 24px; left: 435.68px; top: 341.35px;}

        #warped .w33 {-moz-transform: rotate(-6.85rad);-webkit-transform: rotate(-6.85rad);-o-transform:
        rotate(-6.85rad);-ms-transform: rotate(-6.85rad); transform: rotate(-6.85rad);
        width: 19px; height: 24px; left: 475.11px; top: 327.83px;}

        #warped .w34 {-moz-transform: rotate(-7.03rad);-webkit-transform: rotate(-7.03rad);-o-transform:
        rotate(-7.03rad);-ms-transform: rotate(-7.03rad); transform: rotate(-7.03rad);
        width: 16px; height: 24px; left: 494.44px; top: 314.25px;}

        #warped .w35 {-moz-transform: rotate(-7.22rad);-webkit-transform: rotate(-7.22rad);-o-transform:
        rotate(-7.22rad);-ms-transform: rotate(-7.22rad); transform: rotate(-7.22rad);
        width: 22px; height: 24px; left: 507.59px; top: 296.23px;}

        #warped .w36 {-moz-transform: rotate(-7.37rad);-webkit-transform: rotate(-7.37rad);-o-transform:
        rotate(-7.37rad);-ms-transform: rotate(-7.37rad); transform: rotate(-7.37rad);
        width: 9px; height: 24px; left: 524.82px; top: 279.06px;}

        #warped .w37 {-moz-transform: rotate(-7.48rad);-webkit-transform: rotate(-7.48rad);-o-transform:
        rotate(-7.48rad);-ms-transform: rotate(-7.48rad); transform: rotate(-7.48rad);
        width: 11px; height: 24px; left: 530.03px; top: 265.62px;}

        #warped .w38 {-moz-transform: rotate(-7.59rad);-webkit-transform: rotate(-7.59rad);-o-transform:
        rotate(-7.59rad);-ms-transform: rotate(-7.59rad); transform: rotate(-7.59rad);
        width: 13px; height: 24px; left: 534.65px; top: 249.03px;}

        #warped .w39 {-moz-transform: rotate(-7.69rad);-webkit-transform: rotate(-7.69rad);-o-transform:
        rotate(-7.69rad);-ms-transform: rotate(-7.69rad); transform: rotate(-7.69rad);
        width: 13px; height: 24px; left: 538.51px; top: 231.94px;}

        #warped .w40 {-moz-transform: rotate(-7.79rad);-webkit-transform: rotate(-7.79rad);-o-transform:
        rotate(-7.79rad);-ms-transform: rotate(-7.79rad); transform: rotate(-7.79rad);
        width: 16px; height: 24px; left: 539.41px; top: 212.32px;}

</style>