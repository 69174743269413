<template>
    <div class="seccion mod_especialidades" >
        <div class="lista_especialidades" >
            <div class="titulo_especialidades">
                <div class="sublienado3"></div>
                <div class="quienes_somos_titulo">
                    Algunos de nuestros clientes
                </div>
            </div>
            <div class="sub_seccion_especialidades_x">
                <!-- <div class="marca__" style="border: 1px solid black;">
                    <img src="@/assets/clientes/crocs.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="marca__3">
                    <img src="@/assets/clientes/crocs.jpeg" style="width: 90%;" alt="">
                </div>

                <div class="marca__3">
                    <img src="@/assets/clientes/la_media_cancha.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="marca__4" >
                    <img src="@/assets/clientes/columbia.png" style="width: 100%;" alt="">
                </div>

               


               

                <!-- <div class="marca__">
                    <img src="@/assets/clientes/el_marinero.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="marca__">
                    <img src="@/assets/clientes/comunicaciones.png" style="width: 100%;" alt="">
                </div>

                <!-- <div class="marca__">
                    <img src="@/assets/clientes/anabel_y_paulina.jpeg" style="width: 100%;" alt="">
                </div> -->

                <!-- <div class="marca__">
                    <img src="@/assets/clientes/aparicio.png" style="width: 100%;" alt="">
                </div> -->

               

                <div class="marca__">
                    <img src="@/assets/clientes/meso_panza_verde.png" style="width: 100%;" alt="">
                </div>

                <div class="marca__">
                    <img src="@/assets/clientes/hoteles_peten.png" style="width: 100%;" alt="">
                </div>

                <div class="marca__">
                    <img src="@/assets/clientes/palermo.png" style="width: 100%;" alt="">
                </div>

               

                <div class="marca__">
                    <img src="@/assets/clientes/ricon_de_la_finca.jpeg" style="width: 100%;" alt="">
                </div>

                

                 <div class="marca__">
                    <img src="@/assets/clientes/limage.jpeg" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/cantel.png" style="width: 100%;" alt="">
                </div>

                

                 <div class="marca__">
                    <img src="@/assets/clientes/alpi.jpeg" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/casa_escobar.png" style="width: 100%;" alt="">
                </div>

                

                <div class="marca__2">
                    <img src="@/assets/clientes/kawaii.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="marca__2">
                    <img src="@/assets/clientes/isuzu.png" style="width: 100%;" alt="">
                </div>

                <div class="marca__">
                    <img src="@/assets/clientes/bazar_casa_vieja.jpeg" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/el_telar_de_antigua.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="marca__">
                    <img src="@/assets/clientes/CMI.jpeg" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/monitcelli.jpeg" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/skinpharma.png" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/sleep_galery.jpeg" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/petrus.png" style="width: 100%;" alt="">
                </div>

                <!-- <div class="marca__">
                    <img src="@/assets/clientes/cacao70.png" style="width: 100%;" alt="">
                </div> -->


                 <div class="marca__">
                    <img src="@/assets/clientes/de_museo.png" style="width: 100%;" alt="">
                </div>

                 <div class="marca__">
                    <img src="@/assets/clientes/saul.png" style="width: 100%;" alt="">
                </div>

                <div class="marca__2">
                    <img src="@/assets/clientes/montanos.jpeg" style="width: 100%;" alt="">
                </div>

                 <!-- <div class="marca__">
                    <img src="@/assets/clientes/chiviri4ta.jpeg" style="width: 100%;" alt="">
                </div> -->

                
               
                
            </div>
        </div>
        <!-- <div class="selector mod_selector_especialidades">
            <div class="btn_navegacion">
                <div class="arrow_l" @click="seleccionAnterior">
                    <i class="fas fa-arrow-left"></i>
                </div>
                <div class="arrow_r" @click="seleccionSiguiente">
                    <i class="fas fa-arrow-right"></i>
                </div>
            </div>
            
            <Gestion_restaurant v-if="especialidad == 'gestion'" />
            <TenicoMineria v-if="especialidad == 'mineria'" />
            <Programacion v-if="especialidad == 'programacion'" />
            <Reparacion v-if="especialidad == 'reparacion'" />
            <Construccion v-if="especialidad == 'construccion'" />
            <Veterinaria v-if="especialidad == 'veterinaria'" />
            <Turismo v-if="especialidad == 'turismo'" />
            <Agroindustria v-if="especialidad == 'agroindustria'" />


        </div> -->

        <!-- <div class="rocket">
            <img src="@/assets/rocket.png" style="width: 100%;" alt="">
        </div> -->



    </div>
</template>

<script>

// import Gestion_restaurant from '@/components/Especialidades/Gestion_de_restaurant.vue'
// import TenicoMineria from '@/components/Especialidades/Tecnico_mineria.vue'
// import Programacion from '@/components/Especialidades/Programacion_web.vue'
// import Reparacion from '@/components/Especialidades/Mantenimiento.vue'
// import Construccion from '@/components/Especialidades/Construccion_modular.vue'
// import Veterinaria from '@/components/Especialidades/Auxiliar_en_veterinaria.vue'
// import Turismo from '@/components/Especialidades/Tecnico_turismo.vue'
// import Agroindustria from '@/components/Especialidades/Agroindustria.vue'

export default {
    name: 'Especialidades',
    components:{
        // Gestion_restaurant,
        // TenicoMineria,
        // Programacion,
        // Reparacion,
        // Construccion,
        // Veterinaria,
        // Turismo,
        // Agroindustria
    },
    data() {
        return {
            especialidad: 'gestion',
            listaEspecialidades: ['gestion', 'mineria', 'programacion', 'reparacion', 'construccion', 'veterinaria', 'turismo', 'agroindustria'],
            posicion: 0
        }
    },
    methods: {
        cambioSeccion(clave, posicion){
            this.especialidad = clave
            this.posicion = posicion
        },
        seleccionSiguiente(){
            if (this.posicion >= 7) {
                // console.log(this.posicion)
            }else{
                this.posicion = this.posicion + 1
                this.especialidad = this.listaEspecialidades[this.posicion]
            }
        },
        seleccionAnterior(){
            
            if (this.posicion <= 0) {
                // console.log(1)
            }else{
                this.posicion = this.posicion - 1
                this.especialidad = this.listaEspecialidades[this.posicion]
            }
        }
    },
}
</script>

<style>
    
    .lista_especialidades{
        width: 100%;
        /* height: 250px; */
        
    }


        .sublienado3{
            width: 70px;
            height: 0px;
            border: 2px solid orange;
            margin-bottom: 15px;
        }


        .titulo_especialidades{
            width: 100%;
            height: 60px;
            margin-bottom: 15px;
        }

        .sub_seccion_especialidades_x{
            width: 100%;
            height: calc(100% - 80px);
            display: flex;
            flex-wrap: wrap;
        }

            .marca__{
                width: 200px;
                height: 200px;
                
                margin-right: 10px;
                margin-bottom: 20px;
            }
                .marca__2{
                    width: 300px;
                    height: 200px;
                    
                    margin-right: 10px;
                    margin-bottom: 20px;
                }

                .marca__3{
                    width: 350px;
                    height: 200px;
                    
                    margin-right: 10px;
                }

                .marca__4{
                    width: 200px;
                    height: 200px;
                    margin-right: 10px;
                    
                }

            .lista_especialidades_1{
                width: 35%;
                height: 100%;
            }

                .sub_seccion_especialidades_y{
                    display: flex;
                }

                .numero_especialidad{
                    width: 50px;
                    height: 30px;
                    color: #1bc6c0;
                    font-size: 22px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }

                .texto_especialidad{
                    width: calc(100% - 50px);
                    display: flex;
                    align-items: center;
                    color: #7a7a7a;
                    font-size: 23px;
                }

            .lista_especialidades_2{
                width: 35%;
                height: 100%;
            }


    .selector{
        width: 100%;
        height: calc(100% - 250px);
    }

    .mod_especialidades{
        height: auto !important;
        margin-top: 70px;
        padding-left: 70px;
        padding-right: 70px;
        padding-bottom: 40px;
        background-color: white;
    }



    .rocket{
        width: 350px;
        height: 350px;
        position: absolute;
        top: -45px;
        right: 0px;
        z-index: -1;
    }

    .mod_selector_especialidades{
        height: auto;
        user-select: none;
        display: flex;
        margin-top: 35px;
    }
        .btn_navegacion{
            width: 10%;
            height: 50px;
            border: 3px solid #1bc5bf;
            border-radius: 8px;
            display: flex;
            margin-top: 30px;
        }
            .arrow_l{
                background-color: #1bc5bf;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 25px;
                color: white;
                cursor: pointer;
            }
            .arrow_r{
                background-color: white;
                width: 50%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                color: #1bc5bf;
                font-size: 25px;
                cursor: pointer;
            }
   
    @media only screen and (min-width : 320px) and (max-width: 768px){

        .lista_especialidades{
            height: auto; /* Cambiar por auto al final */
        }

        .sub_seccion_especialidades_x{
            display: block;
        }

        .quienes_somos_titulo{
            font-size: 30px;
        }

            .marca__3{
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .marca__4{
                
                margin: auto;
                margin-bottom: 10px;
            }

            .marca__{
                
                margin: auto;
                margin-bottom: 10px;
            }

            .marca__2{
                margin: auto;
                margin-bottom: 10px;
            }

            .lista_especialidades_1{
                width: 100%;
                height: auto;
                margin-top: 20px;
            }

            .lista_especialidades_2{
                width: 100%;
                height: auto;
            }


            .rocket{
                display: none;
            }
            
            .mod_selector_especialidades{
                display: block;
            }


            .btn_navegacion{
                width: 45%;
                height: 45px;
                margin: auto;
            }


            .mod_especialidades{
                padding-left: 35px !important;
                padding-right: 35px !important;
                box-sizing: border-box;
            }

            .texto_especialidad{
                font-size: 19px;
            }

            .sub_seccion_especialidades_y{
            }

            .mod_numero_4{
                width: initial !important;
            }
    }
</style>