<template>
    <div class="cuerpo_galeria">
        <div class="galeria_baner">
            <div class="btn_cerrar_galeria" @click="cerrar">
                X
            </div>
        </div>
        <div class="galeria_cuadricula">
            <div class="galeria_titulo">
                GALERIA
            </div>
            <div class="galeria_fotos">
                <!-- <div class="foto_i_galeria" @click="abrir_modal(1)">
                    <div class="numero_">
                        1
                    </div>
                    <img src="@/assets/fotos_fabrica/1.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(2)">

                    

                    <img src="@/assets/fotos_fabrica/2.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(3)">

                    <div class="numero_">
                        3
                    </div>

                    <img src="@/assets/fotos_fabrica/3.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(4)">

                    <div class="numero_">
                        4
                    </div>

                    <img src="@/assets/fotos_fabrica/4.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(5)">

                    <div class="numero_">
                        5
                    </div>

                    <img src="@/assets/fotos_fabrica/5.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(6)">

                    

                    <img src="@/assets/fotos_fabrica/6.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(7)">

                    

                    <img src="@/assets/fotos_fabrica/7.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(8)">
                    
                    <div class="numero_">
                        8
                    </div>


                    <img src="@/assets/fotos_fabrica/8.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(9)">

                    


                    <img src="@/assets/fotos_fabrica/9.jpeg" style="width: 100%;" alt="">
                </div>

                

                


                <div class="foto_i_galeria" @click="abrir_modal(11)">

                    

                    <img src="@/assets/fotos_fabrica/11.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(12)">

                    <div class="numero_">
                        12
                    </div>

                    <img src="@/assets/fotos_fabrica/12.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(13)">

                    
                    <img src="@/assets/fotos_fabrica/13.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(14)">

                    <div class="numero_">
                        14
                    </div>
                    <img src="@/assets/fotos_fabrica/14.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(15)">

                    
                    <img src="@/assets/fotos_fabrica/15.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(16)">

                    <div class="numero_">
                        16
                    </div>
                    <img src="@/assets/fotos_fabrica/16.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(17)">

                    <div class="numero_">
                        17
                    </div>
                    <img src="@/assets/fotos_fabrica/17.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(18)">

                
                    <img src="@/assets/fotos_fabrica/18.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(19)">

                
                    <img src="@/assets/fotos_fabrica/19.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(20)">

                
                    <img src="@/assets/fotos_fabrica/20.jpeg" style="width: 100%;" alt="">
                </div>


                <!-- <div class="foto_i_galeria" @click="abrir_modal(21)">

                    <div class="numero_">
                        21
                    </div>
                    <img src="@/assets/fotos_fabrica/21.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(22)">

                    <img src="@/assets/fotos_fabrica/22.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(23)">

                    <img src="@/assets/fotos_fabrica/23.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(24)">

                    <img src="@/assets/fotos_fabrica/24.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(25)">

                    
                    <img src="@/assets/fotos_fabrica/25.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(26)">

                    <div class="numero_">
                        26
                    </div>
                    <img src="@/assets/fotos_fabrica/26.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(27)">

                    <div class="numero_">
                        27
                    </div>
                    <img src="@/assets/fotos_fabrica/27.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(28)">

                    <div class="numero_">
                        28
                    </div>
                    <img src="@/assets/fotos_fabrica/28.jpeg" style="width: 100%;" alt="">
                </div> -->

                <div class="foto_i_galeria" @click="abrir_modal(29)">

                    
                    <img src="@/assets/fotos_fabrica/29.jpeg" style="width: 100%;" alt="">
                </div>

                <div class="foto_i_galeria" @click="abrir_modal(30)">

                    
                    <img src="@/assets/fotos_fabrica/30.jpeg" style="width: 100%;" alt="">
                </div>

                <!-- <div class="foto_i_galeria" @click="abrir_modal(10)">

                    
                    <img src="@/assets/fotos_fabrica/10.jpeg" style="width: 100%;" alt="">
                </div> -->

            </div>
        </div>


        <ModalFotos v-if="modale" :id_foto="fotos_seleccionada" v-on:salir="cerrar_modal" />


    </div>
</template>

<script>

import ModalFotos from './ModalFotos.vue'

export default {
    name: 'Galeria',
    components:{
        ModalFotos
    },
    data() {
        return {
            modale: false,
            fotos_seleccionada: ''
        }
    },
    methods: {
        cerrar(){
            this.$emit('salir')
        },
        cerrar_modal(){
            this.modale = false
        },
        abrir_modal(id){
            this.fotos_seleccionada = id
            this.modale = true
        }
    },
}
</script>

<style scoped>
    .cuerpo_galeria{
        width: 100%;
        height: 100vh;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1100;
    }
        .galeria_baner{
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            padding-right: 10px;
        }
        
        .galeria_cuadricula{
            width: 100%;
            height: calc(100% - 40px);
            
        }
            .galeria_titulo{
                font-size: 30px;
                padding-left: 10px;
                padding-top: 10px;

            }

            .galeria_fotos{
                width: 100%;
                height: 100%;
                padding-left: 20px;
                padding-right: 20px;
                margin-top: 20px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                overflow: auto;
                padding-bottom: 90px;
            }

            .foto_i_galeria{
                width: 300px;
                height: 200px;
                margin-bottom: 10px;
                position: relative;
                overflow: hidden;
                cursor: pointer;


                position: relative;
            }

            .numero_{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-weight: bold;
                font-size: 60px;
            }

            .btn_cerrar_galeria{
                width: 30px;
                height: 30px;
    
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                background-color: rgb(135, 126, 126);
                color: white;
                cursor: pointer;
            }

    ::-webkit-scrollbar{
        display: none;
    }
</style>