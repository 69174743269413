<template>
    <div class="contenedor_pie">
        Todos los derechos reservados por ASMI ©️ 2022.
    </div>
</template>

<script>
export default {

}
</script>

<style>
    .contenedor_pie{
        width: 100%;
        height: 90px;
        background-color: #fe8501;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 14px;
    }


    @media only screen and (min-width : 320px) and (max-width: 768px){
        .contenedor_pie{
            text-align: justify !important;
            padding-left: 35px !important;
            padding-right: 35px !important;
            box-sizing: border-box;
            font-size: 8px;
        }
    }
</style>