<template>
    <div class="seccion mod_como_lo_hacemos">
        <div class="seccion_sub_como_lo_hacemos_1">
            <div class="lista_como_lo_hacemos_1">


                <div class="cuadro_3 show3">
                    <div class="numero_cuadro_3">
                        <div class="inner_numero_cuadro_3">
                            <img src="@/assets/n3.png" style="width: 100%;" alt="">
                        </div>
                    </div>
                    <div class="texto_s3">
                        Nuestro programa contiene un total de <b class="resaltado">216 horas anuales</b>, distribuidas en <b>6 horas</b> por semana, las cuales se programan en <b>90 minutos</b> por día por <b>3 días a la semana.</b>
                    </div>
                </div>

                <!-- <div class="cuadro_3">
                    <div class="numero_cuadro_3">
                        <div class="inner_numero_cuadro_3">
                            <img src="@/assets/n4.png" style="width: 100%;" alt="">
                        </div>
                    </div>
                    <div class="texto_s3">
                        El programa completo de tres años de secundaria contiene <b class="resaltado">648 horas lectivas</b>, adicionalmente a <b class="resaltado">352 horas de práctica laboral.</b> <div class="resaltado_2">
                        <br>    
                        <i>En total el egresado recibirá 1000 horas entre entrenamiento y prácticas laborales.ffff</i></div>
                    </div>
                </div> -->

            </div>
            <div class="movil_chica">
                <img src="@/assets/chica.png" class="imgchica" style="width: 100%;" alt="">
            </div>
            <div class="lista_como_lo_hacemos_2">
                <div class="quienes_somos_titulo mod_quienes_somos">
                    Cómo lo hacemos
                </div>

                <div class="lista_">
                    <div class="cuadro_numerado">
                        <div class="numero_cuadro_1">
                            <div class="inner_numero_cuadro_1">
                                <img src="@/assets/n1.png" style="width: 100%;" alt="">
                            </div>
                        </div>
                        <div class="texto_s1">
                            Nuestra metodología <b>PBS</b> (Positive Behavior Support) sustentada en conceptos pragmáticos, permite desarrollar en cada alumno su predisposición hacia el aprendizaje, la empatía, tolerancia a la frustración, proactividad y adaptabilidad debido al fuerte énfasis aspiracional aplicado.
                        </div>
                    </div>
                </div>


                <div class="lista_">
                    <div class="cuadro_numerado">
                        <div class="numero_cuadro_1">
                            <div class="inner_numero_cuadro_1">
                                <img src="@/assets/n2.png" style="width: 100%;" alt="">
                            </div>
                        </div>
                        <div class="texto_s1">
                            Cada alumno será entrenado, guiado y retroalimentado por <b class="resaltado">especialistas globales expertos</b> y exitosos en sus áreas, prevaleciendo el acompañamiento progresivo, la casuística, la práctica y conceptos estructurales actualizados. Está comprobado que aprender de los errores y corregirlos tiene mayor retención en el alumno que cualquier otra metodología teórica.
                        </div>
                    </div>
                </div>

                <div class="lista_ espacio_bottom ocultar">
                    <div class="cuadro_numerado">
                        <div class="numero_cuadro_1">
                            <div class="inner_numero_cuadro_1">
                                <img src="@/assets/n3.png" style="width: 100%;" alt="">
                            </div>
                        </div>
                        <div class="texto_s1">
                            Nuestro programa contiene un total de <b class="resaltado">216 horas anuales</b>, distribuidas en <b>6 horas</b> por semana, las cuales se programan en <b>90 minutos</b> por día por <b>3 días a la semana.</b>
                        </div>
                    </div>
                </div>








                

                <div class="lista_">
                    <!-- <div class="cuadro_numerado show1">

                        <div class="numero_cuadro_1">
                            <div class="inner_numero_cuadro_1">
                                <img src="@/assets/n4.png" style="width: 100%;" alt="">
                            </div>
                        </div>
                        
                        <div class="texto_s1">
                            El programa completo de tres años de secundaria contiene <b class="resaltado">648 horas lectivas</b>, adicionalmente a <b class="resaltado">352 horas de práctica laboral.</b> <div class="resaltado_2">
                            <br>
                            <i>En total el egresado recibirá 1000 horas entre entrenamiento y prácticas laborales.</i></div>
                        </div>
                    </div> -->

                    <div class="cuadro_numerado show2">

                        <div class="numero_cuadro_1">
                            <div class="inner_numero_cuadro_1">
                                <img src="@/assets/n3.png" style="width: 100%;" alt="">
                            </div>
                        </div>
                        <div class="texto_s1">
                            Nuestro programa contiene un total de <b class="resaltado">216 horas anuales</b>, distribuidas en <b>6 horas</b> por semana, las cuales se programan en <b>90 minutos</b> por día por <b>3 días a la semana.</b>
                        </div>
                    </div>

                    

                </div>


                <div class="lista_ espacio_bottom ">
                        <div class="cuadro_numerado">
                            <div class="numero_cuadro_1">
                                <div class="inner_numero_cuadro_1">
                                    <img src="@/assets/n4.png" style="width: 100%;" alt="">
                                </div>
                            </div>
                            <div class="texto_s1">
                                El programa completo de tres años de secundaria contiene <b class="resaltado">648 horas lectivas</b>, adicionalmente a <b class="resaltado">352 horas de práctica laboral.</b> <div class="resaltado_2">
                                <br>
                                <i>En total el egresado recibirá 1000 horas entre entrenamiento y prácticas laborales.</i></div>
                            </div>
                        </div>
                    </div>

            </div>
            <!-- Desaparecer lista_como_lo_hacemos_1 y que aparezca el tres incrustado  -->
        </div>

        <div class="gradient_chica">
            <img src="@/assets/chica.png" style="width: 100%;" alt="">
        </div>


    </div>
</template>

<script>
export default {

}
</script>

<style>
    
    


    .seccion_sub_como_lo_hacemos_1{
        width: 100%;
        height: 100%;
        display: flex;
        padding-left: 100px;
    }

        .lista_como_lo_hacemos_1{
            width: 50%;
            height: 100%;
            position: relative;
        }

        .lista_como_lo_hacemos_2{
            width: 50%;
            height: 100%;
            padding-top: 130px;
            box-sizing: border-box;
            padding-right: 100px;
        }
            .lista_{
                margin-bottom: 45px;
            }

            .cuadro_numerado{
                display: flex;
                position: relative;
            }

                .numero_cuadro_1{
                    width: 80px;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -50px;
                }

                .inner_numero_cuadro_1{
                    display: flex;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                   
                }

                .texto_s1{
                    color: #7a7a7a;
                    width: 100%;
                    height: 100%;
                    padding-left: 15px;
                }

                    .resaltado{
                        text-decoration-line: underline;
                        text-decoration-color: #1bc5bf;
                    }


                    .resaltado_2{
                        color: #1bc5bf;
                        
                    }

    .gradient_chica{
        width: 600px;
        position: absolute;
        top: -10px;
        left: 0;
        z-index: -1;
    }

        .movil_chica{
            display: none;
        }

        /* .imgchica{
            position: absolute;
        } */


    .cuadro_3{
        width: 80%;
        height: 100px;
        position: absolute;
        left: 0;
        bottom: 55px;
        display: flex;
    }

        .ocultar{
            display: none;
        }

        .numero_cuadro_3{
            
            width: 80px;
            height: 100%;
            display: flex;
            justify-content: center;
        }
            .inner_numero_cuadro_3{
                display: flex;
                justify-content: center;
                width: 50px;
                height: 50px;
                
            }

        .texto_s3{
            color: #7a7a7a;
            width: calc(100% - 80px);
            height: 100%;
            padding-left: 15px;
           
        }

            .texto_s3 b{
                text-decoration-line: underline;
                text-decoration-color: #1bc5bf;
            }


    .show1{
        display: none;
    }

    .show3{
        display: none;
    }

    @media only screen and (min-width : 320px) and (max-width: 768px){

        .seccion_sub_como_lo_hacemos_1{
            display: block;
            padding-left: 50px;
        }

            .show2{
                display: none;
            }

            .show1{
                display: block;
            }

            .lista_como_lo_hacemos_1{
                display: none;
            }

            .gradient_chica{
                display: none;
            }
                .movil_chica{
                    display: block;
                }

            .lista_como_lo_hacemos_2{
                width: 100%;
                height: 100%;
                padding-right: 0px;
                padding-top: 30px;
            }

            .ocultar{
                display: block;
            }

            .espacio_bottom{
                margin-bottom: 60px;
            }


            .mod_como_lo_hacemos{
                height: auto !important;
                padding-left: 35px !important;
                padding-right: 35px !important;
                box-sizing: border-box;
            }


            .mod_quienes_somos{
                font-size: 28px !important;
            }
    }

    /* @media only screen and (min-width :1006px) and (max-width: 1280px){
        .lista_{
            margin-bottom: 28px;
        }
    }



    @media only screen and (min-width : 1135px) and (max-width: 1644px){
        .lista_{
            margin-bottom: 106px;
        }
    } */
</style>