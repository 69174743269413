<template>
    <div class="contendor_contactenos">
        <div class="direccion">
            <div class="datos_texto_1">
                NO DUDE EN CONTACTARNOS
            </div>
           
            <div class="texto_direccion">
                DIRECCIÓN:
            </div>
            <div class="calle">
                5ta. Avenida 9-57 Zona 11, Ciudad de Guatemala
            </div>
            <div class="texto_direccion" style="margin-top: 8px;">
                TELÉFONO:
            </div>
            <div class="calle">
                +502 2440 8107
            </div>

            <div class="texto_direccion" style="margin-top: 8px;">
                CORREO :
            </div>
            <div class="calle">
                ventas@bolsasasmi.com
            </div>
        </div>


        <!-- <div class="formulario">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.8235276828136!2d-90.54534908457262!3d14.609126689797096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a1751a5bcb33%3A0x5c5bfdc5131a962d!2s5A%20Avenida%20957%2C%20Cdad.%20de%20Guatemala!5e0!3m2!1ses!2sgt!4v1661880692933!5m2!1ses!2sgt" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div> -->

        <!-- <div class="formulario">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.206209880005!2d-90.54367717076502!3d14.609051999362311!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a1751b1b0839%3A0x25fa363f71d1e30a!2s5A%20Avenida%209-47%2C%20Cdad.%20de%20Guatemala!5e0!3m2!1ses!2sgt!4v1662675347543!5m2!1ses!2sgt" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div> -->


        <div class="formulario">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d965.2061264527885!2d-90.54367117076501!3d14.609070999362322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc4b9886d646eaa4b!2zMTTCsDM2JzMyLjciTiA5MMKwMzInMzUuMyJX!5e0!3m2!1ses!2sgt!4v1662743970113!5m2!1ses!2sgt" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        


        <!-- <div class="btn_enviar">
            <button class="btn_send" @click="enviarmensajey">ENVIAR</button>
        </div> -->
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'Contactanos',
    data() {
        return {
            pie_nombre: '',
            pie_correo: '',
            pie_mensaje: ''
        }
    },
    methods: {
        async enviarmensajey(){
            if (this.pie_nombre == '' || this.pie_correo == '' || this.pie_mensaje == '') {
                alert('Todos los campos deben estar llenos')
            }else{
                await axios.get(`https://www.escuelatecnicape.com/y.php?nombre=${this.pie_nombre}&correo=${this.pie_correo}&mensaje=${this.pie_mensaje}`)

                alert('Muchas gracias! estarémos contactandote lo más pronto posible.')
                this.pie_nombre = ''
                this.pie_correo = ''
                this.pie_mensaje = ''
            }
        }
    },
}
</script>

<style>
    .contendor_contactenos{
        width: 100%;
        height: 350px;
        display: flex;
        background-color: #272726;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 30px;
    }

        .direccion{
            width: 50%;
            height: 100%;
            padding-top: 15px;
        }

            .datos_texto_1{
                    /* color: #fe8501; */
                    color: white;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                }

                .estec_p{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 70px;
                    margin-top: 25px;
                }

                .inner_estec_p{
                    width: 220px;
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .texto_direccion{
                    color: #fe8501;
                    margin-top: 33px;
                    text-align: center;
                    font-size: 18px;
                }

                .calle{
                    text-align: center;
                    font-size: 18px;
                    font-weight: bold;
                    color: white;
                }

        .formulario{
                width: 50%;
                height: 290px;
                
            }

                .input_formulario{
                    border: 2px solid #fe8501 !important;
                    height: 2rem !important;
                    margin-top: 35px !important;
                    border-radius: 4px !important;
                    background-color: #f6f6f6 !important;
                    padding-left: 4px !important;
                    box-sizing: border-box !important;
                }

                .txtarea{
                     border: 2px solid #fe8501 !important;
                        margin-top: 15px !important;
                        border-radius: 4px !important;
                        background-color: #f6f6f6 !important;
                        padding-left: 4px !important;
                        box-sizing: border-box !important;
                        height: 180px;
                        resize: none;
                }

                ::placeholder{
                    color: #7a7a7a;
                }

        .btn_enviar{
            width: 10%;
            height: 100%;
            position: relative;
        }
            .btn_send{
                width: 100%;
                height: 40px;
                border-radius: 4px;
                border: none;
                background-color: #fe8501;
                color: white;
                font-size: 18px;
                letter-spacing: 0.20em;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 50px;
            }


    @media only screen and (min-width : 320px) and (max-width: 768px){
        .contendor_contactenos{
            display: block;
            height: auto;
            padding-right: 10px;
            padding-left: 10px;
        }

        .direccion{
            width: 100%;
        }

        .formulario{
            width: 100%;
        }

        .btn_enviar{
            width: 100%;
            display: flex;
            justify-content: center;
        }

            .btn_send{
                position: unset;
                margin-bottom: 20px;
                width: 30%;
                
            }
    }
</style>