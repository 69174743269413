<template>
    <div class="seccion mod_seccion_quienes_somos" >
        <!-- <div class="rotulo_estec" >
            <img src="@/assets/logo_estec.png" style="width: 100%;" alt="">
            <div class="sublienado"></div>
        </div> -->
        <div class="seccion_sub_quienes_1">
            <div class="quienes_somos">

                <div class="quienes_somos_titulo">
                    Quiénes Somos
                </div>
                
                <div class="quienes_somos_parrafo colorx mod_parrafo_quienes_somos_1" >
                    <!-- ASMI, es una empresa que se constituyó en 1988 para la fabricación de bolsas de papel tipo boutique y tipo comercial.
                    Actualmente abastece el mercado centroamericano, fabricando para las marcas más reconocidas. <br><br> -->

                    ASMI, es una empresa que se constituyó en 1988. Cuenta con más de 30 años de trabajo en manufactura de bolsas de papel. <br><br>

                    Una de las fortalezas es la capacidad de atender tanto volúmenes grandes como pequeños, contando con una integración vertical de proceso y mano de obra altamente capacitada e identificada con la empresa.
                    
                </div>


                <div class="ver_galeria" @click="abrir_galeria">
                    Ver galería
                </div>

                <!-- <div class="fotos_de_fabrica">
                    
                    <div class="foto_fabrica" @click="abrir_modal(1)">
                        <img src="@/assets/fab_1.jpg" style="width: 100%;height:100%;" alt="">
                    </div>


                    <div class="foto_fabrica" @click="abrir_modal(2)">
                        <img src="@/assets/fab_2.jpg" style="width: 100%;height:100%;" alt="">
                    </div>


                    <div class="foto_fabrica" @click="abrir_modal(3)">
                        <img src="@/assets/fab_3.jpg" style="width: 100%;height:100%;" alt="">
                    </div>


                </div> -->

            </div>
            <!-- <div class="espacio">

            </div> -->
            <div class="proposito_vision">
                
                <div class="nuestro_proposito_titulo">
                    <div class="icono">
                        <img src="@/assets/moutain.png" style="width: 100%;" alt="">
                    </div>
                    <div class="titulo_proposito_vision">
                        Nuestro Propósito
                    </div>
                </div>  
                <div class="nuestro_proposito_parrafo">
                    Es ser la mejor opción para nuestros cliente en fabricación de bolsas de papel en el mercado regional, ser reconocidos por el servicio personalizado, la calidad y el soporte en el desarrollo de nuevos productos, ayudando al posicionamiento de la marca de nuestros clientes en el mercado objetivo.
                    <div class="sublienado2"></div>
                </div>
                <div class="nuestro_proposito_titulo" style="margin-top: 50px;">
                    <div class="icono">
                        <img src="@/assets/lentes.png" style="width: 100%;" alt="">
                    </div>
                    <div class="titulo_proposito_vision">
                        Nuestra Visión
                    </div>
                </div>
                <div class="nuestra_vision_parrafo">
                    Brindar un servicio personalizado e integral para asi exceder las expectativas de nuestros clientes.
                    <div class="sublienado2"></div>
                </div>
            </div>

        </div>

        <Galeria v-if="galeria" v-on:salir="cerrar_galeria" />


    </div>
</template>

<script>

// import ModalFotos from '../mods/ModalFotos.vue'
import Galeria from '../mods/Galeria.vue'

export default {
    name: 'QuienesSomos',
    components:{
        // ModalFotos
        Galeria
    },
    data() {
        return {
            // foto_elegida: 0,
            // modal: false
            galeria: false
        }
    },
    methods: {
        // abrir_modal(id){
        //     this.modal = true
        //     this.foto_elegida = id
        // },
        // cerrar_modal(){
        //     this.modal = false
        // }

        abrir_galeria(){
            this.galeria = true
        },
        cerrar_galeria(){
            this.galeria = false
        }
    },
}
</script>

<style>

    .mod_seccion_quienes_somos{
        height: 390px !important;
    }

    .rotulo_estec{
        width: 100%;
        height: 300px;
        padding-left: 100px;
        padding-right: 100px;
        
    }

        .seccion_sub_quienes_1{
            width: 100%;
            height: calc(100% - 150px);
            display: flex;
        }

        .sublienado{
            width: 70px;
            height: 0px;
            border: 2px solid orange;
            margin-left: 45px;
            margin-top: -5px;
        }

        .sublienado2{
            width: 70px;
            height: 0px;
            border: 2px solid orange;
            margin-top: 10px;
        }

        .quienes_somos{
            width: 50%;
            height: 100%;
            padding-left: 100px;
        }
            .quienes_somos_titulo{
                width: 100%;
                height: 50px;
                font-weight: bold;
                font-size: 35px;
                line-height: 35px;
            }

            .colorx{
                color: #7a7a7a;
            }

            .colorx_celeste{
                color: #1bc6c0;
            }

            .mod_parrafo_quienes_somos_1{
                font-size: 20px;
                margin-top: 25px;
            }

        .proposito_vision{
            width: 50%;
            height: 100%;
            padding-right: 100px;
            padding-left: 50px;
        }

            .nuestro_proposito_titulo{
                width: 100%;
                height: 50px;
                font-weight: bold;
                display: flex;
            }

            .icono{
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }

            .titulo_proposito_vision{
                width: calc(100% - 50px);
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 18px;
            }

            .nuestro_proposito_parrafo{
                margin-top: 15px;
                color: #7a7a7a;
                font-size: 17px;
                line-height: 22px;
            }

            .nuestra_vision_parrafo{
                margin-top: 15px;
                color: #7a7a7a;
                font-size: 17px;
                line-height: 22px;
            }

    .fotos_de_fabrica{
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: space-between;

    }

        .foto_fabrica{
            width: 150px;
            height: 150px;
            transition: .4s ease;
            cursor: pointer;
        }

            .foto_fabrica:hover{
                transform: scale(1.1);
                
            }

    .ver_galeria{
        cursor: pointer;
        width: 150px;
        height: 50px;
        margin-top: 15px;
        font-size: 28px;
        font-weight: bold;
    }


    @media only screen and (min-width : 320px) and (max-width: 768px){
        .rotulo_estec{
            padding-left: 10px;
            padding-right: 10px;
            display: flex;
            align-items: center;
        }

        .seccion_sub_quienes_1{
            display: block;
        }
         .quienes_somos{
             width: 100%;
             height: 50%;
             padding-left: 0px;
             padding-bottom: 45px;
         }

         .proposito_vision{
             width: 100%;
             height: 50%;
             padding-right: 0px;
             padding-left: 0px;
             padding-bottom: 45px;
         }

         .mod_seccion_quienes_somos{
            height: auto !important;
            padding-left: 35px !important;
            padding-right: 35px !important;
            box-sizing: border-box;
         }
    }
</style>